html, body, #root {
  height: 100%;
}
body {
  background: #333;
  margin: 0;
  display: grid;
  padding: 3em;
  place-items: center;
}
.boxes {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(2, minmax(350px, 1fr));
  grid-template-rows: auto;
}
.box {
  display: flex;
  background: #ebebeb;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  border: 3px solid #962626;
  h1 {
    font-size: 20px;
    margin: 0 0 1rem 0;
  }
  .content {
    padding-top: 1em;
  }
  ol {
    margin-left: 0;
    li {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }
  }
}
.button {
  background: #962626;
  border: 2px solid transparent;
  color: white;
  margin-right: 1em;
  padding: .25em .75em;
  transition: all .25s ease-in;
  &:hover {
    background: #21a621;
    border: 2px solid transparent;
    color: white;
  }
}

.button2 {
  background: #21a621;
  border: 2px solid transparent;
  border-radius: 5px;
  color: white;
  margin-right: 1em;
  padding: .25em .75em;
  transition: all .25s ease-in;
  &:hover {
    background: #962626;
    border: 2px solid transparent;
    color: white;
  }
}